import { Box, Button, Checkbox, Collapse, Dialog, IconButton, Radio, TextField, Typography } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'
import { getUsersClean } from '../../API/users'
import { end_buttons, inline, inline_buttons, inline_space, inline_space_no_wrap, loader_container, text_space } from '../../Utils/defaultStyles'
import { ArrowDownward, Clear, FilterList, Search } from '@material-ui/icons'
import newTheme from '../../newTheme'
import useToggle from '../../Hooks/ToogleHook'
import ToggleIcon from '../Icons/ToggleIcon'
import AppContext from '../../AppContext'
import { CurrentUserContext } from '../../CurrentUser'
import ScrollContainer from '../Scroll/ScrollContainer'
import { Sync } from '@mui/icons-material'
import LoaderAnimator from '../Loader/LoaderAnimator'
import { removeAccents } from '../../Utils/functions'

const css = {
	dialog: {
		margin: 3,
		position: 'relative'
	},
	branch: {
		margin: '0 0 24px 0'
	},
	name: {
		background: newTheme.palette.background.main,
		padding: "2px 8px",
		borderRadius: 1,
		display: 'flex',
		alignItems: 'center',
		gap: 1
	},
	names: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 1,
		maxHeight: 75,
		overflowY: 'auto',
	},
	button: {
		position: 'fixed',
		bottom: 24,
		right: 24,
	},
	users: {
		maxHeight: 'calc(100vh - 450px)',
		overflowY: 'auto',
		margin: '12px 0',
		padding: "0 12px 0 0",
		'@media (max-width:600px)': {
			maxHeight: 'calc(100vh - 350px)',
		}
	},
	branch_name: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 2,
	},
	sorting: {
		display: 'flex',
		alignItems: 'center',
		gap: 1,
		padding: '4px 12px',
		background: newTheme.palette.background.main,
		borderRadius: 1,
		cursor: 'pointer',
	},
	sortings: {
		display: 'flex',
		alignItems: 'center',
		gap: 1,
		flexWrap: 'wrap',
		margin: '6px 0'
	},
}

const sortings = [
	{ name: 'Nombre', value: 'name' },
	{ name: 'Rut', value: 'rut' },
	{ name: 'Fecha de ingreso', value: 'created_at' },
]

function BranchUser({ element, addUser, selected, alone, addMultipleUsers, sortBy, filterBy, isMultiple, openBranch, setOpenBranch }) {
	const { currentUser } = useContext(CurrentUserContext)
	const branch = element?.branch || {}
	const users = element?.users || {}

	// Sort users by sortBy value
	const sorted_by_sortBy = users.sort((a, b) => {
		if (a[sortBy] < b[sortBy]) return -1
		if (a[sortBy] > b[sortBy]) return 1
		return 0
	})

	// Filter users by filterBy value if their user type is in findBy
	const filtered_by_filterBy = sorted_by_sortBy.filter(user => {
		return filterBy.includes(user.user_type_id);
	});

	// Group by supervised by the current users and those who are not
	const supervised = filtered_by_filterBy.filter(user => user.supervisor_id === parseInt(currentUser.id, 10))
	const not_supervised = filtered_by_filterBy.filter(user => user.supervisor_id !== parseInt(currentUser.id, 10))
	const hasSupervised = supervised.length > 0

	const isWholeBranchSelected = users.map(user => user.id).every(id => selected.map(element => element.id).includes(id))

	function onSelectBranch() {
		const option = isWholeBranchSelected ? 'remove' : 'add'
		addMultipleUsers(users, option)
	}

	function toggleOpen() {
		if (openBranch === branch.id) return setOpenBranch(0)
		setOpenBranch(branch.id)
	}

	const SelectorToUse = isMultiple ? Checkbox : Radio
	const open = openBranch === branch.id
	const isAnotherOpened = openBranch !== 0 && openBranch !== branch.id
	if (isAnotherOpened) return null
	if (hasSupervised) {
		return (
			<Box sx={css.branch}>
				<Box sx={{ ...inline_space, ...css.branch_name }} onClick={toggleOpen}>
					<Box sx={inline_buttons}>
						{isMultiple && <Checkbox onClick={onSelectBranch} checked={isWholeBranchSelected} />}
						<Typography variant='h4'>{branch?.name}</Typography>
					</Box>
					{!alone &&
						<IconButton onClick={toggleOpen} size="small">
							<ToggleIcon open={open} />
						</IconButton>
					}
				</Box>
				{(open || alone) &&
					<Box>
						<Typography variant='h4' sx={{ padding: 2 }}>Supervisados</Typography>
						<Box sx={{ paddingLeft: 2 }}>
							{supervised.map(user => {
								// Check if user is selected
								const is_selected = selected.map(element => element.id).includes(user.id)
								return (
									<Box sx={{ ...inline, paddingLeft: 2 }} key={user.id} onClick={() => addUser(user)}>
										<SelectorToUse checked={is_selected} />
										<Typography key={user.id} variant='subtitle1'>{user.name}{!!user.contractor_name ? ` - ${user.contractor_name}` : ""}</Typography>
									</Box>
								)
							})}
						</Box>
						{!!not_supervised.length && <Typography variant='h4' sx={{ padding: 2 }}>No supervisados</Typography>}
						<ScrollContainer height={58} spacing={0} maxHeight={300}>
							{not_supervised.map(user => {
								// Check if user is selected
								const is_selected = selected.map(element => element.id).includes(user.id)
								return (
									<Box sx={{ ...inline, paddingLeft: 2 }} key={user.id} onClick={() => addUser(user)}>
										<SelectorToUse checked={is_selected} />
										<Typography key={user.id} variant='subtitle1'>{user.name}{!!user.contractor_name ? ` - ${user.contractor_name}` : ""}</Typography>
									</Box>
								)
							})}
						</ScrollContainer>
					</Box>
				}
			</Box >
		)
	}

	return (
		<Box sx={css.branch}>
			<Box sx={{ ...inline_space, ...css.branch_name }} onClick={toggleOpen}>
				<Box sx={inline_buttons}>
					{isMultiple && <Checkbox onClick={onSelectBranch} checked={isWholeBranchSelected} />}
					<Typography variant='h4'>{branch?.name}</Typography>
				</Box>
				{!alone &&
					<IconButton onClick={toggleOpen} size="small">
						<ToggleIcon open={open} />
					</IconButton>
				}
			</Box>
			{(open || alone) &&
				<Box>
					<ScrollContainer height={58} spacing={0} maxHeight={300}>
						{filtered_by_filterBy.map(user => {
							// Check if user is selected
							const is_selected = selected.map(element => element.id).includes(user.id)
							return (
								<Box sx={{ ...inline, paddingLeft: 2 }} key={user.id} onClick={() => addUser(user)}>
									<SelectorToUse checked={is_selected} />
									<Typography key={user.id} variant='subtitle1'>{user.name}{!!user.contractor_name ? ` - ${user.contractor_name}` : ""}</Typography>
								</Box>
							)
						})}
					</ScrollContainer>
				</Box>
			}
		</Box>
	)
}

export const UserSelectContext = createContext()

/**
 * Custom hook to access the user dialog functionality.
 * @returns {Function} The function to open the user dialog.
	* @param {Function} [callback=null] - The callback function to be executed after the dialog is closed.
	* @param {Array} [selected_users=[]] - The array of selected user IDs.
	* @param {string|null} [branch_id=null] - The ID of the branch.
	* @param {boolean} [multiple=false] - Indicates whether multiple users can be selected.
	* @param {Array} [limit=[]] - The array of limits.
 */
export function useUserDialog() {
	const { openUsersDialog } = useContext(UserSelectContext);
	return openUsersDialog
}

function UserSelectDialog({ children }) {

	const [users_by_branch_object, setUsers] = useState([])
	const [open, setOpenDialog] = useState(false)
	const [multiple, setMultiple] = useState(false)
	const [callback, setCallback] = useState(() => { })
	const [branch_id, setBranch_id] = useState(null)
	const [selected, setSelected] = useState([])
	const [search, setSearch] = useState("")
	const { online: { status: online } } = useContext(AppContext)
	const { currentUser } = useContext(CurrentUserContext)
	const [sortBy, setSortBy] = useState('name')
	const [filterBy, setFilterBy] = useState([1, 2, 3])
	const [openFilters, toggleFilters] = useToggle(false)
	const [openBranch, setOpenBranch] = useState(0)
	const [limit, setLimit] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const loggedIn = !!localStorage.getItem("account")
		const storedFilters = localStorage.getItem('user_filters')
		const parsedFilters = storedFilters ? JSON.parse(storedFilters) : []
		const initialFilterBy = !!parsedFilters?.length ? parsedFilters : [1, 2, 3]
		setFilterBy(initialFilterBy)

		async function fetchData() {
			const response = await getUsersClean()
			setUsers(response.data.info)
		}
		if (currentUser?.id && online && loggedIn) { fetchData() }
		window.addEventListener("update_users", fetchData)
		return () => window.removeEventListener("update_users", fetchData)
	}, [currentUser?.id, online])

	useEffect(() => {
		function reloadFilters() {
			const storedFilters = localStorage.getItem('user_filters')
			const parsedFilters = storedFilters ? JSON.parse(storedFilters) : []
			const initialFilterBy = !!parsedFilters?.length ? parsedFilters : [1, 2, 3]
			setFilterBy(initialFilterBy)
		}
		reloadFilters()
	}, [open])

	useEffect(() => {
		localStorage.setItem('user_filters', JSON.stringify(filterBy))
	}, [filterBy])

	async function handleReload() {
		setLoading(true)
		const response = await getUsersClean()
		setUsers(response.data.info)
		setLoading(false)
	}

	/**
	 * Opens the users dialog.
	 *
	 * @param {Function} [callback=null] - The callback function to be executed after the dialog is closed.
	 * @param {Array} [selected_users=[]] - The array of selected user IDs.
	 * @param {string|null} [branch_id=null] - The ID of the branch.
	 * @param {boolean} [multiple=false] - Indicates whether multiple users can be selected.
	 * @param {Array} [limit=[]] - The array of limits.
	 */
	function openUsersDialog(callback = null, selected_users = [], branch_id = null, multiple = false, limit = [], all_users = []) {
		setOpenDialog(true)
		setMultiple(multiple)
		setCallback(() => callback || (() => { }))
		setBranch_id(branch_id)
		setLimit(limit)

		// Selected usually is an array of ids, so we need to get all the users object
		const users_options = all_users.length ? all_users : users_by_branch_object
		const users = selected_users.map(id => users_options
			.map(element => element.users)
			.flat()
			.find(user => String(user.id) === String(id)))
			.filter(Boolean)

		setSelected(users)
	}

	function filterUsersBySearchValue(elements) {
		if (search === "") return elements
		// filter by user name or rut, and if the branch has the user inside, otherwise remove the branch
		const normalizedSearch = removeAccents(search.toLowerCase())
		const filtered = elements.map(element => {
			const branch = element.branch
			const users = element.users.filter(user => removeAccents(user.name).toLowerCase().includes(normalizedSearch))
			return users.length > 0 ? { branch, users: users } : false
		}).filter(Boolean)
		return filtered
	}

	function addOrRemoveUser(user) {
		// selected is an array of users
		// Check if user in selected by id
		if (selected.map(element => element.id).includes(user.id)) {
			// Remove user from selected
			setSelected(selected.filter(element => element.id !== user.id))
		} else {
			// Add user to selected
			if (multiple) {
				setSelected([...selected, user])
			} else {
				setSelected([user])
				callback && callback([user])
				setOpenDialog(false)
			}
		}
	}

	function addMultipleUsers(users, option = "add") {
		if (option === "add") return setSelected([...selected, ...users])
		if (option === "remove") return setSelected(selected.filter(element => !users.map(user => user.id).includes(element.id)))
	}

	function filterBranch(elements) {
		// If branch_id present then filter all other branches out
		let filter_elements = [...elements]

		// If there is a restricted list of users filter them out
		// Limit is an array of users that CAN be selected

		if (!!limit.length) {
			filter_elements = elements.map(element => {
				const branch = element.branch
				const users = element.users.filter(user => limit.includes(user.id))
				return { branch, users: users }
			}).filter(element => element.users.length > 0)
		}

		// branch_id could be a string of branches joined by commas
		// so we need to split it and filter by each branch

		if (branch_id) {
			const branch_ids = branch_id.split(",")
			return filter_elements.filter(element => branch_ids.includes(String(element.branch.id)))
		}


		return filter_elements
	}

	function getUsersNames(user_ids) {

		// If user_ids is a single string, then return a single name

		if (!Array.isArray(user_ids)) {
			const user = users_by_branch_object
				.map(element => element.users)
				.flat()
				.find(user => String(user.id) === String(user_ids))
			return user?.name
		}

		return user_ids.flat().map(id => users_by_branch_object
			.map(element => element.users)
			.flat()
			.find(user => String(user.id) === String(id))?.name).join(", ")
	}

	function getUsersObjects(user_ids) {
		if (!Array.isArray(user_ids)) {
			const user = users_by_branch_object
				.map(element => element.users)
				.flat()
				.find(user => String(user.id) === String(user_ids))
			return user || {}
		}

		if (user_ids.length === 0) return []

		return user_ids.flat().map(id => users_by_branch_object
			.map(element => element.users)
			.flat()
			.find(user => String(user.id) === String(id)))
	}

	function getCurrentBranchUsers() {
		// Get all users for the current branch as an array
		const users = users_by_branch_object.find(element => String(element.branch.id) === String(branch_id))?.users || []
		return users
	}

	function onSubmit() {
		callback && callback(selected)
		setOpenDialog(false)
	}

	function getUsers() {
		// Get all users for the current branch as an array
		const users = users_by_branch_object.map(element => element.users).flat()
		return users
	}

	function handleClose() {
		setOpenDialog(false)
		setFilterBy([1, 2, 3])
		toggleFilters(false)
		setSearch("")
		setSelected([])
		setOpenBranch(0)
	}

	const filtered_users_by_branch_object = filterUsersBySearchValue(users_by_branch_object)
	let final_users = filterBranch(filtered_users_by_branch_object)
	const isCollaborator = currentUser?.user_type_id === 3
	if (isCollaborator) {
		// Filter all branches, to only show the current user in the list
		final_users = final_users.map(element => {
			const branch = element.branch
			const users = element.users.filter(user => user.id === parseInt(currentUser.id, 10))
			return { branch, users: users }
		})
	}

	const handleFilter = (type) => {
		setFilterBy((prevFilter) => prevFilter.includes(type) ? prevFilter.filter((id) => id !== type) : [...prevFilter, type]);
	}

	return (
		<Box>
			<Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth fullScreen={window.innerWidth < 600}>
				<Box sx={css.dialog}>
					<Box sx={inline_space}>
						<Typography variant='h1'>Selección de usuarios </Typography>
						<IconButton size="large" onClick={handleClose}>
							<Clear />
						</IconButton>
					</Box>
					<Box sx={css.filters}>
						<Box sx={inline_space_no_wrap}>
							<IconButton onClick={toggleFilters}>
								<FilterList />
							</IconButton>
							<Button onClick={handleReload} color="info" variant="outlined" endIcon={<Sync />}>
								Recargar información
							</Button>
						</Box>
						<Collapse in={openFilters}>
							<Box>
								<Box>
									<Typography variant='subtitle2'>Ordenar por:</Typography>
									<Box sx={css.sortings}>
										{sortings.map((sorting, index) => {
											const is_selected = sortBy === sorting.value
											return (
												<Box sx={css.sorting} onClick={() => setSortBy(sorting.value)} key={index}>
													<Typography variant='subtitle1'>{sorting.name}</Typography>
													{is_selected && <ArrowDownward />}
												</Box>
											)
										})}
									</Box>
								</Box>
								<Box>
									<Typography variant='subtitle2'>Filtrar por:</Typography>
									<Box>
										<Box sx={inline_buttons}>
											{/* If all types are selected, this unselect all when clicked, otherwise it selects all */}
											<Checkbox onClick={() => setFilterBy((prevFilter) => prevFilter.length === 3 ? [] : [1, 2, 3])} checked={filterBy.length === 3} />
											<Typography variant='subtitle1'>Todos</Typography>
										</Box>
										<Box sx={inline_buttons}>
											{/* If the type is selected, it unselect it, otherwise it adds it to the filterBy */}
											<Checkbox onClick={() => handleFilter(1)} checked={filterBy.includes(1)} />
											<Typography variant='subtitle1'>Administradores</Typography>
										</Box>
										<Box sx={inline_buttons}>
											<Checkbox onClick={() => handleFilter(2)} checked={filterBy.includes(2)} />
											<Typography variant='subtitle1'>Supervisores</Typography>
										</Box>
										<Box sx={inline_buttons}>
											<Checkbox onClick={() => handleFilter(3)} checked={filterBy.includes(3)} />
											<Typography variant='subtitle1'>Colaboradores</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Collapse>
					</Box>
					<TextField
						label="Buscar"
						variant="standard"
						fullWidth
						sx={{ margin: '6px 0' }}
						InputProps={{
							endAdornment: <Search />,
						}}
						value={search}
						onChange={e => setSearch(e.target.value)}
					/>

					{selected.length > 0 &&
						<Box>
							<Typography variant='h4' sx={text_space} >Usuarios seleccionados: </Typography>
							<Box sx={css.names}>
								{selected.map((user, index) =>
									<Box key={user?.id} sx={css.name}>
										<Typography variant='subtitle1'>{user?.name}</Typography>
										<IconButton size="small" onClick={() => addOrRemoveUser(user)}>
											<Clear />
										</IconButton>
									</Box>
								)}
							</Box>
						</Box>
					}


					{loading ?
						<Box sx={loader_container}>
							<LoaderAnimator />
						</Box>
						:
						<Box sx={css.users}>
							{final_users.map(element =>
								<BranchUser
									addUser={addOrRemoveUser}
									key={element.branch.id}
									element={element}
									selected={selected}
									alone={final_users.length === 1}
									addMultipleUsers={addMultipleUsers}
									sortBy={sortBy}
									filterBy={filterBy}
									isMultiple={multiple}
									openBranch={openBranch}
									setOpenBranch={setOpenBranch}
								/>
							)}
						</Box>}
					<Box sx={end_buttons}>
						<Button color="info" variant="contained" size="large" onClick={onSubmit}>
							Guardar y Salir
						</Button>
					</Box>
				</Box>
			</Dialog>
			<UserSelectContext.Provider value={{ openUsersDialog, getUsersNames, getUsers, getUsersObjects, getCurrentBranchUsers }}>
				{children}
			</UserSelectContext.Provider>
		</Box>
	)
}

export default UserSelectDialog